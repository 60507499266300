import { useState } from 'react'
import sword from '../../assets/dagger.png'
import poison_sword from "../../assets/store/PoisonDagger.png"
import shield from '../../assets/shield_new.png'
import medicine from '../../assets/store/Medicine.png'
import reflect from "../../assets/store/ReflectiveShield.png"
import jester from '../../assets/store/JesterHat.png'
import { BattleAction } from './BattleAction'
import { BattleState } from '../../lib/localStorage'

type Props = {
    battleState: BattleState
    onClick: (value: string) => void,
}

export const Actions = ({ battleState, onClick }: Props) => {

    const [value, setValue] = useState("")
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [hasPoisonDagger] = useState(battleState.player.equipped.includes('PoisonDagger'))

    const handleClick = () => {
        onClick(value)
    }

    const handleSelection = (value: string) => {
        setValue(value)

        if (value === sword) {
            setTitle("Knife")
            setDescription("Every letter discovery is worth 1 attack, 2 attack if it's in the correct location. No attack is rewarded if letter was previously found")
        } else if (value === poison_sword) {
            setTitle("Poison Knife")
            setDescription("Every letter discovery is worth 1 attack, 2 attack if it's in the correct location. No attack is rewarded if letter was previously found. A successful strike will inflict poison.")
        } else if (value === shield) {
            setTitle("Shield")
            setDescription("Every letter discovered in the correct location results in 2 Shields. Correct letters in the wrong location result in nothing.")
        } else if (value === medicine) {
            setTitle("Medicine - " + battleState.player.bandages + " left")
            setDescription("Base heal for 3hp and every letter discovery restores and additional 1 hp, 2 hp if it's in the correct location. No hp is rewarded if letter was previously found. Consumable.")
        } else if (value === jester) {
            setTitle("Jester Hat")
            setDescription("Tell a cringey joke. Damaging yourself equal to the guess score but damaging your opponent double")
        } else if (value === reflect) {
            setTitle("Reflective Shield")
            setDescription("Requires a guess score of 4 or higher to work. Will cast reflect on hero and reward shields equal to the units level. Unlike normal shields it does not rely on correct letter discoveries, just the score.")
        }
    }

    const showDescription = () => {
        return (
            <>
                <p className="text-xs text-gray-500 dark:text-gray-300 mt-6">
                    {title}
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-300 mt-6">
                    {description}
                </p>
                <button
                    className={"w-26 h-14 border-solid border-2 items-center justify-center mt-6 px-6 text-xs font-bold rounded dark:text-white"}
                    onClick={handleClick}
                    >
                        {"Select"}
                </button>
            </>
        )
    }
    console.log(battleState.player.equipped)

    return (
        <>
            <div className='flex justify-center mt-6'>
                {battleState.player.equipped.includes('PoisonDagger') ?
                    <BattleAction key={1} value={poison_sword} onClick={handleSelection} /> :
                    <BattleAction key={1} value={sword} onClick={handleSelection} />
                }
                <BattleAction key={2} value={shield} onClick={handleSelection} />
                {battleState.player.bandages > 0 && <BattleAction key={3} value={medicine} onClick={handleSelection} />}
                
            </div>
            <div className='flex justify-center mt-6'>
                {battleState.player.equipped.includes('JesterHat') && <BattleAction key={4} value={jester} onClick={handleSelection} />}
                {battleState.player.equipped.includes('ReflectiveShield') && <BattleAction key={4} value={reflect} onClick={handleSelection} />}
            </div>
            {value.length > 0 ? showDescription() : <></>}
        </>
    )
}
