import { unit } from './localStorage'
import sword from '../assets/dagger.png'

const calculateVampireSiphon = (unit: unit, guessValue: number): number => {
    if (guessValue === 0) return 0

    return Math.min(2 * unit.level, guessValue)
}

const hasPoisonDagger = (items: string[]): boolean => {
    return items.includes('PoisonDagger') || items.includes('PoisonDagger2')
  }

const calculatePoisonDamage = (unit: unit): number => {
    let poisonScalar = (unit.poisonCombo - (unit.poisonCombo % 2)) / 2
    return 1 + poisonScalar
}

export const processAttack = (attacker: unit, attackee: unit, guessValue: number) => {
    let updates = []
    let scaledGuessValue = guessValue - (attackee.afflictions.includes('reflect') ? guessValue : 0)

    updates.push({ 
        update: 'shields', 
        type: attackee.name, 
        value: Math.max(0, attackee.shields - scaledGuessValue)})

    let remainingAttack = Math.max(0, scaledGuessValue - attackee.shields)
    let attackeeCurrent = Math.max(0, attackee.current - remainingAttack)

    updates.push({ 
        update: 'current', 
        type: attackee.name, 
        value: attackeeCurrent})

    let hadReflect = attackee.afflictions.includes('reflect')
    let attackeeAfflictions = attackee.afflictions.filter((affliction) => affliction !== 'reflect')

    if (attacker.equipped.includes('PoisonDagger')) {
        updates.push({
            update: 'afflictions',
            type: attackee.name,
            value: hasPoisonDagger(attacker.equipped) && !attackeeAfflictions.includes('poison') ? 
                [...attackeeAfflictions, 'poison'] : attackeeAfflictions
        })
    }

    let attackerCurrent = attacker.current
    let poisonCombo = attacker.poisonCombo

    if (attacker.equipped.includes('VampireCloak')) {
        attackerCurrent = Math.min(attacker.total, attacker.current + calculateVampireSiphon(attacker, remainingAttack))
    }

    if (attacker.afflictions.includes('poison')) {
        attackerCurrent = attackerCurrent - calculatePoisonDamage(attackee)
        poisonCombo += 1
    } else {
        poisonCombo = 0
    }

    if (hadReflect) {
        attackerCurrent = Math.max(0, attackerCurrent - (guessValue * 2))
    }

    updates.push({ 
        update: 'current', 
        type: attacker.name, 
        value: attackerCurrent
    })
    updates.push({ 
        update: 'poisonCombo', 
        type: attacker.name, 
        value: poisonCombo
    })

    return updates
}

export const processShields = (unit: unit, guessValue: number) => {
    let updates = []

    if (unit.afflictions.includes('poison')) {
        updates.push(
            { update: 'current', type: unit.name, value: Math.max(0, unit.current - calculatePoisonDamage(unit)) }
        )
        updates.push(
            { update: 'poisonCombo', type: unit.name, value: unit.poisonCombo + 1 }
        )
    } else {
        updates.push(
            { update: 'poisonCombo', type: unit.name, value: 0 }
        )
    }

    updates.push({ update: 'shields', type: unit.name, value: unit.shields + guessValue })

    return updates
}

export const processHeal = (unit: unit, guessValue: number) => {
    let bandageCount = Math.max(0, unit.bandages - 1)
    let unitHealth = unit.current + 3 + guessValue
    let poisonCombo = unit.poisonCombo

    if (unit.afflictions.includes('poison')) {
        unitHealth = Math.max(0, unitHealth - calculatePoisonDamage(unit))
        poisonCombo += 1
    } else {
        poisonCombo = 0
    }

    return [
        { update: 'current', type: unit.name, value: Math.min(unit.total, unitHealth) },
        { update: 'bandages', type: unit.name, value: bandageCount }, 
        { update: 'action', type: unit.name, value: bandageCount === 0 ? sword : unit.action },
        { update: 'poisonCombo', type: unit.name, value: poisonCombo }
    ]
}

export const processJester = (attacker: unit, attackee: unit, guessValue: number) => {
    let updates = []
    
    let attackerCurrent = Math.max(0, attacker.current - guessValue)
    let poisonCombo = attacker.poisonCombo

    if (attacker.equipped.includes('VampireCloak')) {
        attackerCurrent = Math.min(attacker.total, attacker.current + calculateVampireSiphon(attacker, guessValue))
    }

    if (attacker.afflictions.includes('poison') && guessValue > 0) {
        attackerCurrent = attackerCurrent - calculatePoisonDamage(attackee)
        poisonCombo += 1
    } else {
        poisonCombo = 0
    }

    let attackeeHadReflect = attackee.afflictions.includes('reflect')
    let attackeeAfflictions = attackee.afflictions
    if (attackeeHadReflect) {
        attackerCurrent = Math.max(0, attackerCurrent - (guessValue * 4))
        attackeeAfflictions = attackeeAfflictions.filter((affliction) => affliction !== 'reflect')
    }

    updates.push({ 
        update: 'current', 
        type: attacker.name, 
        value: attackerCurrent})

    updates.push({ 
        update: 'poisonCombo', 
        type: attacker.name, 
        value: poisonCombo})

    let opponentGuessValue = attackeeHadReflect ? 0 : guessValue * 2
    
    updates.push({ 
        update: 'shields', 
        type: attackee.name, 
        value: Math.max(0, attackee.shields - opponentGuessValue)})

    let remainingAttack = Math.max(0, opponentGuessValue - attackee.shields)

    let attackeeCurrent = Math.max(0, attackee.current - remainingAttack)
    
    updates.push({ 
        update: 'current', 
        type: attackee.name, 
        value: attackeeCurrent})

    updates.push({
        update: 'afflictions',
        type: attackee.name,
        value: hasPoisonDagger(attacker.equipped) && opponentGuessValue > 0 && !attackeeAfflictions.includes('poison') ? 
            [...attackeeAfflictions, 'poison'] : attackeeAfflictions
    })

    return updates
}

export const processReflectiveShield = (unit: unit, guessValue: number) => {
    let newCurrent = unit.current - (unit.afflictions.includes('poison') ? calculatePoisonDamage(unit) : 0)
    let poisonCombo = unit.afflictions.includes('poison') ? unit.poisonCombo + 1 : 0

    return [
        { update: 'current', type: unit.name, value: newCurrent },
        { update: 'poisonCombo', type: unit.name, value: poisonCombo },
        { update: 'shields', type: unit.name, value: guessValue >= 4 ? unit.shields + unit.level : unit.shields },
        { 
            update: 'afflictions', 
            type: unit.name, 
            value: guessValue >= 4 && !unit.afflictions.includes('reflect') ? 
                [ ...unit.afflictions, "reflect"] : unit.afflictions
        }
    ]
}

export const getUpdate = (type: string, update: string, updates: any[], fallback: any) => {
    for (let item of updates) {
        if (item.update === update && item.type === type) {
            return item.value
        }
    }

    return fallback
}