import { unit } from "../../lib/localStorage"
import { purchaseData, purchaseLookup, imageLookup } from "./Items"

type Props = {
	unit: unit
    items: any[]
    row: number[]
    select: (data: purchaseData) => void
}

export const ShelfRow = ({ unit, items, row, select }: Props) => {
	return (
        <div className="scale-150 flex justify-center mb-12">
            {row.map((_, i) => {
                return (
                    <button
                        key={i}
                        type="button"
                        className="mx-4 flex items-center px-2 py-2 border border-1 rounded hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
                        onClick={() => select(purchaseLookup(items[row[i]], unit.items, unit.level))}
                    >
                        <img className="scale-150" alt="" src={imageLookup(items[row[i]])} />
                    </button>
                )
            })}
        </div>
	)
}
  