import { getGuessStatuses } from '../../lib/statuses'
import { Cell } from './Cell'
import { ActionCell } from './ActionCell'

type Props = {
  guess: string,
  order: string,
  guessValue: number
}

export const CompletedRow = ({ guess, order, guessValue }: Props) => {
  const statuses = getGuessStatuses(guess)


  return (
    <div className="flex justify-center mb-1">
      <ActionCell key={0} player={true} active={false} action={""} guessValue={guessValue} order={order} onClick={() => {}} />
      {guess.split('').map((letter, i) => (
        <Cell key={i + 1} value={letter} status={statuses[i]} />
      ))}
      <ActionCell key={7} player={false} active={false} action={""} guessValue={guessValue} order={order} onClick={() => {}} />
    </div>
  )
}
