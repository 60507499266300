import poison from "../../assets/poison.png"
import reflect from "../../assets/store/ReflectiveShield.png"

type Props = {
    afflictions?: string[]
}

const getAfflictionImage = (affliction: string) => {
    if (affliction === 'poison') {
        return poison
    } else if (affliction === 'reflect') {
        return reflect
    }

    return poison
}

export const CharacterStatuses = ({ afflictions }: Props) => {
    return (
        <div className="mx-3">
            {afflictions && afflictions.map((affliction, i) => 
                <img key={i} className="scale-75" alt="" src={getAfflictionImage(affliction)} />
            )}
        </div>
    )
}
