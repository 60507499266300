const knifeFightStateKey = "knifeFightState"

export type KnifeFightState = {
  round: number
  storeAvailable: boolean
  wordleState: StoredGameState
  battleState: BattleState
}

export const saveKnifeFightState = (knifeFightState: KnifeFightState) => {
  localStorage.setItem(knifeFightStateKey, JSON.stringify(knifeFightState))
}

export const loadKnifeFightStateFromLocalStorage = () => {
  const state = localStorage.getItem(knifeFightStateKey)
  return state ? (JSON.parse(state) as KnifeFightState) : null
}

const gameStateKey = 'gameState'

export type StoredGameState = {
  seed: number
  guesses: string[]
  guessOrder: string[]
  solution: string
}

export const saveGameStateToLocalStorage = (gameState: StoredGameState) => {
  localStorage.setItem(gameStateKey, JSON.stringify(gameState))
}

export const loadGameStateFromLocalStorage = () => {
  const state = localStorage.getItem(gameStateKey)
  return state ? (JSON.parse(state) as StoredGameState) : null
}

const randomSeedKey = "randomSeed"

export const saveRandomSeed = (seed: number) => {
  localStorage.setItem(randomSeedKey, JSON.stringify(seed))
}

export const loadRandomSeedFromLocalStorage = () => {
  const state = localStorage.getItem(gameStateKey)
  return state ? (JSON.parse(state) as StoredGameState).seed : 0
}

const gameStatKey = 'gameStats'

export type GameStats = {
  winDistribution: number[]
  gamesFailed: number
  currentStreak: number
  bestStreak: number
  totalGames: number
  successRate: number
}

export const saveStatsToLocalStorage = (gameStats: GameStats) => {
  localStorage.setItem(gameStatKey, JSON.stringify(gameStats))
}

export const loadStatsFromLocalStorage = () => {
  const stats = localStorage.getItem(gameStatKey)
  return stats ? (JSON.parse(stats) as GameStats) : null
}

const battleStateKey = "battleState"

export type unit = {
  name: string
  level: number
  current: number
  total: number
  shields: number
  bandages: number
  action: string
  avatar: string
  equipped: string[]
  items: string[]
  afflictions: string[],
  poisonCombo: number
}

export type BattleState = {
  playerTurn: boolean
  round: number
  money: number
  player: unit
  enemy: unit
}

export const saveBattleState = (battleState: BattleState) => {
  localStorage.setItem(battleStateKey, JSON.stringify(battleState))
}

export const loadBattleStateFromLocalStorage = () => {
  const battleState = localStorage.getItem(battleStateKey)
  return battleState ? (JSON.parse(battleState) as BattleState) : null
}

const bestStreakKey = "bestStreak"

export const saveStreak = (streak: number) => {
  localStorage.setItem(bestStreakKey, JSON.stringify(streak))
}

export const loadBestStreakFromLocalStorage = () => {
  const bestStreak = localStorage.getItem(bestStreakKey)
  return bestStreak ? JSON.parse(bestStreak) as number : 0
}