import { useState } from "react"

import male_1 from "../../assets/avatars/male_1.png"
import male_2 from "../../assets/avatars/male_2.png"
import male_3 from "../../assets/avatars/male_3.png"
// import male_4 from "../../assets/avatars/male_4.png"
import male_5 from "../../assets/avatars/male_5.png"
import male_6 from "../../assets/avatars/male_6.png"
import male_7 from "../../assets/avatars/male_7.png"
import male_8 from "../../assets/avatars/male_8.png"
import male_9 from "../../assets/avatars/male_9.png"
import male_10 from "../../assets/avatars/male_10.png"
import female_1 from "../../assets/avatars/female_1.png"
import female_2 from "../../assets/avatars/female_2.png"
import female_3 from "../../assets/avatars/female_3.png"
import female_4 from "../../assets/avatars/female_4.png"
import female_5 from "../../assets/avatars/female_5.png"
import female_6 from "../../assets/avatars/female_6.png"
import female_7 from "../../assets/avatars/female_7.png"

const avatars = [
    male_1, male_2, male_3, male_5, male_6, male_7, male_8, male_9, male_10, 
    female_1, female_2, female_3, female_4, female_5, female_6, female_7
]


type Props = {
    current: string,
    updateAvatar: (value: string) => void,
}

export const AvatarSelection = ({ current, updateAvatar }: Props) => {

    
    const [index, setIndex] = useState(avatars.findIndex(avatar => avatar === current))

    const rightClick = () => {
        updateAvatar(avatars[(index + 1) % avatars.length])
        setIndex((index + 1) % avatars.length)
    }

    const leftClick = () => {
        let newIndex = index - 1 >= 0 ? index - 1 : avatars.length - 1
        updateAvatar(avatars[newIndex])
        setIndex(newIndex)
    }

    return (
        <div className='flex justify-center m-10'>
            <button
                type="button"
                className="px-1 py-1 mx-10 text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
                onClick={leftClick}
            >
                {"<"}
            </button>
            <div className="scale-150">
                <div className="scale-150">
                    <img className="scale-150 flex items-center justify-center" alt="" src={avatars[index]} />
                </div>
            </div>
            <button
                type="button"
                className="px-1 py-1 mx-10 text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
                onClick={rightClick}
            >
                {">"}
            </button>
        </div>
    )
}
