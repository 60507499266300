import { BaseModal } from './BaseModal'
import { Actions } from '../battle/Actions'
import { BattleState } from '../../lib/localStorage'

type Props = {
  battleState: BattleState
  isOpen: boolean
  handleClose: () => void,
  selectAction: (value: string) => void,
}

export const ActionModal = ({ battleState, isOpen, handleClose, selectAction }: Props) => {
  return (
    <BaseModal title="Choose Action" isOpen={isOpen} handleClose={handleClose}>
      <Actions battleState={battleState} onClick={selectAction} />
    </BaseModal>
  )
}
