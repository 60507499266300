
export const StripeDonation = () => {
    const handleClick = () => {
        window.open("https://buy.stripe.com/5kA03qcXndrealO000", "_blank")?.focus()
    }

    return (
        <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
            onClick={handleClick}
        >
            {"Donate"}
        </button>
    );
};