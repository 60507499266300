import { getWordFromSeed } from './words'
import { loadRandomSeedFromLocalStorage } from './localStorage'

export type CharStatus = 'absent' | 'present' | 'correct' | 'unlocked'

export type CharValue =
  | 'Q'
  | 'W'
  | 'E'
  | 'R'
  | 'T'
  | 'Y'
  | 'U'
  | 'I'
  | 'O'
  | 'P'
  | 'A'
  | 'S'
  | 'D'
  | 'F'
  | 'G'
  | 'H'
  | 'J'
  | 'K'
  | 'L'
  | 'Z'
  | 'X'
  | 'C'
  | 'V'
  | 'B'
  | 'N'
  | 'M'

export const getStatuses = (
  guesses: string[]
): { [key: string]: CharStatus } => {
  const charObj: { [key: string]: CharStatus } = {}
  const seed : number = loadRandomSeedFromLocalStorage()
  const solution = getWordFromSeed(seed).solution

  guesses.forEach((word) => {
    word.split('').forEach((letter, i) => {
      if (!solution.includes(letter)) {
        // make status absent
        return (charObj[letter] = 'absent')
      }

      if (letter === solution[i]) {
        //make status correct
        return (charObj[letter] = 'correct')
      }

      if (charObj[letter] !== 'correct') {
        //make status present
        return (charObj[letter] = 'present')
      }
    })
  })

  return charObj
}

export const getUnlockableLetter = (guesses: string[]): number => {
  let unlockedLetters : number[] = []
  let lockedLetters : number[] = []
  const seed : number = loadRandomSeedFromLocalStorage()
  const solution = getWordFromSeed(seed).solution
  let splitSolution = solution.split('')

  for (let guess of guesses) {
    guess.split('').forEach((letter, i) => {
      if (letter === splitSolution[i] && !unlockedLetters.includes(i)) {
        unlockedLetters.push(i)
      }
    })
  }

  splitSolution.forEach((letter, i) => {
    if (!unlockedLetters.includes(i)) {
      lockedLetters.push(i)
    }
  })

  return lockedLetters[Math.floor(Math.random() * lockedLetters.length)]
}

export const getGuessValue = (guess: string, guesses: string[]) => {
  const seed : number = loadRandomSeedFromLocalStorage()
  const solution = getWordFromSeed(seed).solution
  const splitSolution = solution.split('')

  const correctScore = splitSolution.map((_) => 0)
  const presentScore = splitSolution.map((_) => 0)
  const guessScore = splitSolution.map((_) => 0)

  for (let pastGuess of guesses) {
    pastGuess.split('').forEach((letter, i) => {
      if (letter === splitSolution[i]) {
        correctScore[i] = 2
      }

      if (splitSolution.includes(letter)) { 
        presentScore[i] = 1
      }
    })
  }

  guess.split('').forEach((letter, i) => {
    if (letter === splitSolution[i] && correctScore[i] < 2) {
      guessScore[i] = 2
      return
    }

    if (splitSolution.includes(letter) && 
      !letterHasBeenGuessed(letter, guesses) && !guess.substring(0, i - 1).includes(letter)) {
      guessScore[i] = 1
    }
  })

  const score = guessScore.reduce((partialSum, x) => partialSum + x, 0)
  const solvedScore = guess === solution ? 2 : 0

  return score + solvedScore
}

const letterHasBeenGuessed = (letter: string, guesses: string[]) => {
  for (let guess of guesses) {
    if (guess.includes(letter)) {
      return true
    }
  }

  return false
}

export const getCorrectLetterGuesses = (guess: string, guesses: string[]) => {
  const seed : number = loadRandomSeedFromLocalStorage()
  const solution = getWordFromSeed(seed).solution
  const splitSolution = solution.split('')

  const solutionScore = splitSolution.map((_) => 0)
  const guessScore = splitSolution.map((_) => 0)

  for (let pastGuess of guesses) {
    pastGuess.split('').forEach((letter, i) => {
      if (letter === splitSolution[i]) {
        solutionScore[i] = 2
      }
    })
  }

  guess.toUpperCase().split('').forEach((letter, i) => {
    if (letter === splitSolution[i] && solutionScore[i] < 2) {
      guessScore[i] = 2
      return
    }
  })

  const score = guessScore.reduce((partialSum, x) => partialSum + x, 0)
  const solvedScore = guess === solution ? 2 : 0

  return score + solvedScore
}

export const getGuessStatuses = (guess: string): CharStatus[] => {
  const seed : number = loadRandomSeedFromLocalStorage()
  const solution = getWordFromSeed(seed).solution
  const splitSolution = solution.split('')

  const splitGuess = solution.length > 5 ? guess.slice(0,5).split('').concat([' '], guess.slice(5,10).split('')) : guess.split('')

  const solutionCharsTaken = splitSolution.map((_) => false)

  const statuses: CharStatus[] = Array.from(Array(guess.length))

  // handle all correct cases first
  splitGuess.forEach((letter, i) => {
    if (letter === splitSolution[i]) {
      statuses[i] = 'correct'
      solutionCharsTaken[i] = true
      return
    }
  })

  splitGuess.forEach((letter, i) => {
    if (statuses[i]) return

    if (!splitSolution.includes(letter)) {
      // handles the absent case
      statuses[i] = 'absent'
      return
    }

    // now we are left with "present"s
    const indexOfPresentChar = splitSolution.findIndex(
      (x, index) => x === letter && !solutionCharsTaken[index]
    )

    if (indexOfPresentChar > -1) {
      statuses[i] = 'present'
      solutionCharsTaken[indexOfPresentChar] = true
      return
    } else {
      statuses[i] = 'absent'
      return
    }
  })

  return statuses
}
