import { MAX_WORD_LENGTH } from '../../constants/settings'
import { Cell } from './Cell'
import { ActionCell } from './ActionCell'

export const EmptyRow = () => {
  const emptyCells = Array.from(Array(MAX_WORD_LENGTH))

  return (
    <div className="flex justify-center mb-1">
      <ActionCell key={0} player={true} active={false} action={""} onClick={() => {}} />
      {emptyCells.map((_, i) => (
        <Cell key={i} />
      ))}
      <ActionCell key={7} player={false} active={false} action={""} onClick={() => {}} />
    </div>
  )
}
