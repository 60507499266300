import { WORDS } from '../constants/wordlist'
import { VALIDGUESSES } from '../constants/validGuesses'
import { loadRandomSeedFromLocalStorage } from './localStorage'

export const isWordInWordList = (word: string) => {
  return (
    WORDS.includes(word.toLowerCase()) ||
    VALIDGUESSES.includes(word.toLowerCase())
  )
}

export const isWinningWord = (word: string) => {
  return solution === word
}

export const getWordOfDay = () => {
  // January 1, 2022 Game Epoch
  const seed = loadRandomSeedFromLocalStorage()
  const epochMs = new Date('January 1, 2022 00:00:00').valueOf()
  const now = Date.now()
  const msInDay = 86400000
  const index = Math.floor((now - epochMs) / msInDay)
  const nextday = (index + 1) * msInDay + epochMs

  return {
    solution: WORDS[seed % WORDS.length].toUpperCase(),
    solutionIndex: seed,
    tomorrow: nextday,
  }
}

export const isWinningWordFromSeed = (word:string, seed: number) => {
  return getWordFromSeed(seed).solution === word
}

export const getWordFromSeed = (seed: number) => {
  // January 1, 2022 Game Epoch
  const epochMs = new Date('January 1, 2022 00:00:00').valueOf()
  const now = Date.now()
  const msInDay = 86400000
  const index = Math.floor((now - epochMs) / msInDay)
  const nextday = ((index + seed) + 1) * msInDay + epochMs

  return {
    solution: WORDS[seed % WORDS.length].toUpperCase(),
    solutionIndex: seed,
    tomorrow: nextday,
  }
}

export const getDay = (): number => {
  const epochMs = new Date('January 1, 2022 00:00:00').valueOf()
  const now = Date.now()
  const msInDay = 86400000
  const index = Math.floor((now - epochMs) / msInDay)

  return index
}

export const { solution, solutionIndex, tomorrow } = getWordOfDay()

export const getWordsFromDiscoveredWord = (guesses: string[], discoveredLetters: string[]) => {
  let validWords = WORDS.filter(word => validWord(word, guesses, discoveredLetters))

  return validWords[Math.floor(Math.random() * validWords.length) % validWords.length]
}

const validWord = (word: string, guesses: string[], discoveredLetters: string[]) => {
	if (guesses.includes(word.toUpperCase())) return false

	let wordSplit = word.split('')

	for (let i = 0; i < wordSplit.length; i++) {
		if (discoveredLetters[i] === '') continue

		if (wordSplit[i] !== discoveredLetters[i].toLocaleLowerCase()) {
			return false
		}
	}

	return true
}