import { useState } from 'react'
import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'
import { BattleAction } from '../battle/BattleAction'
import { CharacterCell } from '../battle/CharacterCell'

import { BASE_PLAYER } from '../../constants/units'
import sword from "../../assets/sword.png"
import shield from "../../assets/shield.png"
import medicine from "../../assets/medicine.png"

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {

  const [section, setSection] = useState(0)

  const nextSection = () => {
    setSection((section + 1) % 3)
  }

  const getSection = (page: number) => {
    if (page === 0) {
      return createPartOne()
    } else if (page === 1) {
      return createPartTwo()
    } else if (page === 2) {
      return createPartThree()
    }

    return createPartThree()
  }

  const createPartOne = () => {
    return (
      <>
        <p className="text-xs text-gray-500 dark:text-gray-300">
          Guess the word in 6 tries. After each guess, the color of the tiles will
          change to show how close your guess was to the word.
        </p>

        <div className="flex justify-center mb-1 mt-4">
          <Cell value="W" status="correct" />
          <Cell value="E" />
          <Cell value="A" />
          <Cell value="R" />
          <Cell value="Y" />
        </div>
        <p className="text-xs text-gray-500 dark:text-gray-300">
          The letter W is in the word and in the correct spot.
        </p>

        <div className="flex justify-center mb-1 mt-4">
          <Cell value="P" />
          <Cell value="I" />
          <Cell value="L" status="present" />
          <Cell value="O" />
          <Cell value="T" />
        </div>
        <p className="text-xs text-gray-500 dark:text-gray-300">
          The letter L is in the word but in the wrong spot.
        </p>

        <div className="flex justify-center mb-1 mt-4">
          <Cell value="V" />
          <Cell value="A" />
          <Cell value="G" />
          <Cell value="U" status="absent" />
          <Cell value="E" />
        </div>
        <button
            type="button"
            className="mx-auto mt-8 flex items-center px-5 py-2 border border-transparent text-lg font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
            onClick={nextSection}
        >
            {"Next"}
        </button>
      </>
    )
  }

  const createPartTwo = () => {
    return (
      <>
        <p className="text-xs text-gray-500 dark:text-gray-300">
          Each guess is scored by the discovered letters. Discovering a letter in the word is 1 point, discovering a letter in the word that's also 
          in the right location is 2 points. Points are only given for the guess that finds it first.
        </p>

        <div className="flex justify-center mb-1 mt-4">
          <Cell value="W" status="correct" />
          <Cell value="E" status="present"/>
          <Cell value="A" />
          <Cell value="R" />
          <Cell value="Y" />
        </div>
        <p className="text-xs text-gray-500 dark:text-gray-300">
          This guess is worth 3 points. 2 for W in the correct spot and 1 for E in the incorrect spot.
        </p>

        <div className="flex justify-center mb-1 mt-4">
          <Cell value="W" status="correct" />
          <Cell value="A" />
          <Cell value="R" />
          <Cell value="E" status="correct" />
          <Cell value="S" />
        </div>
        <p className="text-xs text-gray-500 dark:text-gray-300">
          This would result in 2 points. W was already discovered so no points for it, E was not placed correctly previously but now is in the right place
          and provided 2 points.
        </p>
        <button
            type="button"
            className="mx-auto mt-8 flex items-center px-5 py-2 border border-transparent text-lg font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
            onClick={nextSection}
        >
            {"Next"}
        </button>
      </>
    )
  }

  const createPartThree = () => {
    return (
      <>
        <p className="text-xs text-gray-500 dark:text-gray-300">
          The score of your guess powers the move you selected.
        </p>

        <div className="flex justify-center mb-1 mt-4">
          <BattleAction value={sword} onClick={() => null} />
          <BattleAction value={shield} onClick={() => null} />
          <BattleAction value={medicine} onClick={() => null} />
        </div>
        <p className="text-xs text-gray-500 dark:text-gray-300 mt-4">
          You can choose an action every turn. Switching up your strategy is crucial for survival.
        </p>

        <div className="flex justify-center mb-1 mt-4">
          <CharacterCell unit={BASE_PLAYER} animation={""} stopAnimation={() => null} onClick={() => null} />
        </div>
        <p className="text-xs text-gray-500 dark:text-gray-300 mt-8">
          Use wit and strategy to keep your hero alive, the game is over when their health runs out. Knife Fast Knife Hard.
        </p>
        <button
            type="button"
            className="mx-auto mt-8 flex items-center px-5 py-2 border border-transparent text-lg font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
            onClick={closeAndReset}
        >
            {"Close"}
        </button>
      </>
    )
  }

  const closeAndReset = () => {
    handleClose()
    setSection(0)
  }

  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={closeAndReset}>
      {getSection(section)} 
    </BaseModal>
  )
}
