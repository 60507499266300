import { BaseModal } from './BaseModal'
import { Actions } from '../battle/Actions'
import { BattleState } from '../../lib/localStorage'

type Props = {
    background: boolean,
    sound: boolean,
    setBackground: (background: boolean) => void
    setSound: (sound: boolean) => void
    isOpen: boolean
    handleClose: () => void,
}

export const AudioModal = ({ background, sound, setBackground, setSound, isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Audio Settings" isOpen={isOpen} handleClose={handleClose}>
        <div className='flex justify-center mt-6 grow'>
            <p className='text-left text-xs font-bold rounded dark:text-white py-2 px-4 grow'>Background Music</p>
            <label className="switch mx-4">
                <input type="checkbox" checked={background} onClick={() => setBackground(!background)}/>
                <span className="slider round"></span>
            </label>
        </div>
        <div className='flex justify-center mt-6'>
            <p className='text-left text-xs font-bold rounded dark:text-white py-2 px-4 grow'>Sound Effects</p>
            <label className="switch mx-4">
                <input type="checkbox" checked={sound} onClick={() => setSound(!sound)} />
                <span className="slider round"></span>
            </label>
        </div>
    </BaseModal>
  )
}
