import { AvatarSelection } from "./components/avatar/AvatarSelction"
import { BattleState } from "./lib/localStorage"

type Props = {
	battleState: BattleState
	play: () => void,
	continueGame: () => void,
	updateAvatar: (value: string) => void,
}

export const Menu = ({ battleState, play, continueGame, updateAvatar }: Props) => {

	const hasCurrentGame = (battleState: BattleState) => {
		if (battleState.player.current <= 0) return false

		return battleState.round > 0 || battleState.player.current < battleState.player.total || 
			battleState.enemy.current < battleState.enemy.total
	}
	return (
	<div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
		<AvatarSelection current={battleState.player.avatar} updateAvatar={updateAvatar} />
		<button
			type="button"
			className="mx-auto mt-8 flex items-center px-5 py-3 border border-transparent text-2xl font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
			onClick={play}
		>
			{"New Game"}
		</button>
		{ hasCurrentGame(battleState) && (
			<button
				type="button"
				className="mx-auto mt-8 flex items-center px-5 py-3 border border-transparent text-2xl font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
				onClick={continueGame}
			>
				{"Continue"}
			</button>
		)}
	</div>
	)
}
  