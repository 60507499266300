
  import { useState, useEffect } from 'react'
  import { Alert } from './components/alerts/Alert'
  import { Grid } from './components/grid/Grid'
  import { Keyboard } from './components/keyboard/Keyboard'
  import {
    NOT_ENOUGH_LETTERS_MESSAGE,
    WORD_NOT_FOUND_MESSAGE
  } from './constants/strings'
  import { MAX_WORD_LENGTH, MAX_CHALLENGES } from './constants/settings'
  import { getGuessValue } from './lib/statuses'
  import { isWordInWordList, isWinningWordFromSeed } from './lib/words'
  import {
    BattleState,
    StoredGameState,
  } from './lib/localStorage'
  
  import './App.css'
  
  const ALERT_TIME_MS = 2000

  type Props = {
    battleState: BattleState,
    gameState: StoredGameState,
    animating: boolean,
    chooseAction: () => void,
    processGuess: (guess: string, guessValue: number) => void
  }
  
  export const Wordle = ({ battleState, gameState, animating, chooseAction, processGuess }: Props) => {
    const [currentGuess, setCurrentGuess] = useState('')
  
    const [isGameWon, setIsGameWon] = useState(false)
    const [isGameLost, setIsGameLost] = useState(false)
  
    const [isNotEnoughLetters, setIsNotEnoughLetters] = useState(false)
    const [isWordNotFoundAlertOpen, setIsWordNotFoundAlertOpen] = useState(false)

    const [enterPressed, setEnterPressed] = useState(false)
  
    useEffect(() => {
      if (isGameWon) {
        setTimeout(() => {
          setIsGameWon(false)
        }, ALERT_TIME_MS)
      }
      if (isGameLost) {
        setTimeout(() => {
          setIsGameLost(false)
        }, ALERT_TIME_MS)
      }
    }, [isGameWon, isGameLost])
  
    const onChar = (value: string) => {
      if (animating) return

      if (
        currentGuess.length < MAX_WORD_LENGTH &&
        gameState.guesses.length < MAX_CHALLENGES &&
        !isGameWon
      ) {
        setCurrentGuess(`${currentGuess}${value}`)
      }
    }
  
    const onDelete = () => {
      setCurrentGuess(currentGuess.slice(0, -1))
    }
  
    const onEnter = () => {
      if (animating || enterPressed) return
      setEnterPressed(true)

      setTimeout(() => {
        setEnterPressed(false)
      }, 3000)

      if (isGameWon || isGameLost) {
        processGuess(currentGuess, getGuessValue(currentGuess, gameState.guesses))
        return
      }
      if (!(currentGuess.length === MAX_WORD_LENGTH)) {
        setIsNotEnoughLetters(true)
        return setTimeout(() => {
          setIsNotEnoughLetters(false)
        }, ALERT_TIME_MS)
      }
  
      if (!isWordInWordList(currentGuess)) {
        setIsWordNotFoundAlertOpen(true)
        return setTimeout(() => {
          setIsWordNotFoundAlertOpen(false)
        }, ALERT_TIME_MS)
      }
  
      const winningWord = isWinningWordFromSeed(currentGuess, gameState.seed)
  
      if (
        currentGuess.length === MAX_WORD_LENGTH &&
        gameState.guesses.length < MAX_CHALLENGES &&
        !isGameWon
      ) {
        processGuess(currentGuess, getGuessValue(currentGuess, gameState.guesses))
        setCurrentGuess('')
  
        if (winningWord) {
          return setIsGameWon(true)
        }
  
        if (gameState.guesses.length === MAX_CHALLENGES - 1) {
          setIsGameLost(true)
        }
      }
    }
  
    return (
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <Grid guesses={gameState.guesses} guessOrder={gameState.guessOrder} currentGuess={currentGuess} battleState={battleState} chooseAction={chooseAction} />
        <Keyboard
          onChar={onChar}
          onDelete={onDelete}
          onEnter={onEnter}
          guesses={gameState.guesses}
        />
        <Alert message={NOT_ENOUGH_LETTERS_MESSAGE} isOpen={isNotEnoughLetters} />
        <Alert
          message={WORD_NOT_FOUND_MESSAGE}
          isOpen={isWordNotFoundAlertOpen}
        />
      </div>
    )
  }
  