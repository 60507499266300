import classnames from 'classnames'
import { CharacterPortrait } from './CharacterPortrait'
import { CharacterHealth } from './CharacterHealth'
import { CharacterShields } from './CharacterShields'
import { unit } from '../../lib/localStorage'

type Props = {
    unit: unit
    animation: string,
    stopAnimation: () => void,
    onClick: (unit: unit) => void,
}

export const CharacterCell = ({ unit, animation, stopAnimation, onClick }: Props) => {
    const classes = classnames(
        'w-20 h-20 items-center justify-center mx-12'
    )

    const buttonClasses = classnames(
        '', {
            'border-solid border-2 border-green-800 rounded': unit.level === 2,
            'border-solid border-2 border-blue-800 rounded': unit.level === 3,
            'border-solid border-2 border-orange-800 rounded': unit.level === 4,
            'border-solid border-2 border-purple-800 rounded': unit.level === 5,
        }
    )

    return (
        <div className={classes}>
            <button className={buttonClasses} onClick={() => onClick(unit)}>
                <CharacterPortrait value={unit.avatar} afflictions={unit.afflictions} animation={animation} stopAnimation={stopAnimation} />
            </button>
            <CharacterShields total={unit.shields} />
            <CharacterHealth current={unit.current} total={unit.total} />
        </div>
    )
}
