import heal_icon from "../../assets/store/IconCure.png"
import cure_icon from "../../assets/store/IconDispel.png"
import medicine_icon from "../../assets/store/Medicine.png"
import health_icon from "../../assets/store/Heart.png"
import level_icon from "../../assets/store/LevelUp.png"
import poison_icon from "../../assets/store/PoisonDagger.png"
import vampire_icon from "../../assets/store/VampireCloak.png"
import lucky_icon from "../../assets/store/LuckyClover.png"
import jester_icon from "../../assets/store/JesterHat.png"
import speed_icon from "../../assets/store/SpeedBoots.png"
import gold_icon from "../../assets/store/GoldPot.png"
import reflect_icon from "../../assets/store/ReflectiveShield.png"

export type purchaseData = {
    id: string,
    title: string,
    purchaseText: string,
    purchaseDescription: string,
    price: number
}

export const purchaseLookup = (id: string, items: string[], level: number): purchaseData => {
    if (id === 'Heal') {
        return {
            id: id,
            title: "Heal",
            purchaseText: 'Purchase $10',
            purchaseDescription: "I'll heal ya completely but I won't rid ya of that pesky poison.",
            price: 10
        }
    } else if (id === 'Cure') {
        return {
            id: id,
            title: "Cure",
            purchaseText: 'Purchase $10',
            purchaseDescription: "You got poison? I got cure. Take it or leave it.",
            price: 10
        }
    } else if (id === 'Medicine') {
        return {
            id: id,
            title: "Medicine",
            purchaseText: 'Purchase $10',
            purchaseDescription: "Want some of this ole shop keeps hidden serum? Well come right this way. 3 for 10",
            price: 10
        }
    } else if (id === 'UpgradeHealth') {
        return {
            id: id,
            title: "Upgrade Health",
            purchaseText: 'Purchase $50',
            purchaseDescription: "Want some more health? Then buy this friend.",
            price: 50
        }
    } else if (id === 'LevelUp') {
        let price = 200 + (level * 10)
        return {
            id: id,
            title: "Level Up",
            purchaseText: 'Purchase $' + price,
            purchaseDescription: "Want your stab to pack to some more punch? May also affect shields and medicine, I'll never tell.",
            price: price
        }
    } else if (id === 'PoisonDagger') {
        return {
            id: id,
            title: "Poison Dagger",
            purchaseText: 'Purchase $100',
            purchaseDescription: "Add a little poison spice to that dagger, inflicting anything it touches with poison.",
            price: 100
        }
    } else if (id === 'VampireCloak') {
        return {
            id: id,
            title: "Vampire Cloak",
            purchaseText: 'Purchase $100',
            purchaseDescription: "This cloak will set you on a path of darkness, siphoning health for every successful strike. Caps at 2hp * Level. Visiting the store will now damage you as my smile shines bright like the sun.",
            price: 100
        }
    } else if (id === 'GoldPot') {
        return {
            id: id,
            title: "Pot of Gold",
            purchaseText: 'Purchase $55',
            purchaseDescription: "Doubles your earnings, wish I could double my prices. I mean could I...?",
            price: 55
        }
    } else if (id === 'LuckyRoll') {
        return {
            id: id,
            title: "Lucky Clover",
            purchaseText: 'Purchase $35',
            purchaseDescription: "Every successful guess now has a 5% chance to reveal an additional letter.",
            price: 35
        }
    } else if (id === 'JesterHat') {
        return {
            id: id,
            title: "Jesters Hat",
            purchaseText: 'Purchase $50',
            purchaseDescription: "Adds a new Action, You tell a bad joke, damaging yourself equal to the guess score but damaging the opponent double",
            price: 50
        }
    } else if (id === 'SpeedyBoots') {
        return {
            id: id,
            title: "Speedy Boots",
            purchaseText: 'Purchase $50',
            purchaseDescription: "Start every round with the first two guesses, after that you're stuck with your own intelligence.",
            price: 50
        }
    } else if (id === 'ReflectiveShield') {
        return {
            id: id,
            title: "Reflective Shield",
            purchaseText: 'Purchase $80',
            purchaseDescription: "Alternative Shield Action. Guess scores greater than 4 give you a reflect status however only shields equal to the unit level will be given. Reflect status will deflect the opponents next attack back at them for double damage.",
            price: 80
        }
    }

    return { id: "None", title: "None", purchaseText: "", purchaseDescription: "", price: 0}
}

export const imageLookup = (id: string) => {
    if (id === 'Heal') {
        return heal_icon
    } else if (id === 'Cure') {
        return cure_icon
    } else if (id === 'Medicine') {
        return medicine_icon
    } else if (id === 'UpgradeHealth') {
        return health_icon
    } else if (id === 'LevelUp') {
        return level_icon
    } else if (id === 'PoisonDagger') {
        return poison_icon
    } else if (id === 'VampireCloak') {
        return vampire_icon
    } else if (id === 'GoldPot') {
        return gold_icon
    } else if (id === 'LuckyRoll') {
        return lucky_icon
    } else if (id === 'JesterHat') {
        return jester_icon
    } else if (id === 'SpeedyBoots') {
        return speed_icon
    } else if (id === 'ReflectiveShield') {
        return reflect_icon
    } else if (id === 'PoisonDagger2') {
        return poison_icon
    }
}