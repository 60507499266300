import { unit } from "../../lib/localStorage"
import { ShelfRow } from "./ShelfRow"
import { purchaseData } from "./Items"

type Props = {
	unit: unit,
    title: string,
    items: any[],
    select: (data: purchaseData) => void,
}

const getRows = (total: number) => {
    let rowArray = []
    let currentRow: number[] = []

    for (let i = 0; i < total; i++) {
        if (i > 0 && i % 4 === 0) {
            rowArray.push(currentRow)
            currentRow = []
        }

        currentRow.push(i)
    }

    if (currentRow.length > 0) {
        rowArray.push(currentRow)
    }

    return rowArray
}

export const Shelf = ({ unit, items, title, select }: Props) => {

    const rowArrays = getRows(items.length)

	return (
        <>
            <div className="flex justify-center mx-8 my-6">
                <p className="text-xs text-gray-500 dark:text-gray-300 mt-1">
                    {title}
                </p>
            </div>
            {rowArrays.map((row, i) => 
                <ShelfRow key={i} unit={unit} items={items} row={row} select={select} />
            )}
        </>
	)
}
  