import { BaseModal } from './BaseModal'
import { StripeDonation } from '../donate/StripeDonation'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="About" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-xs text-gray-500 dark:text-gray-300 mb-5">
        Turn Based Wordle Inspired Battle System, built on this framework @Hannahcode -{' '}
        <a
          href="https://github.com/hannahcode/GAME"
          className="underline font-bold"
        >
          check out the code here
        </a>{' '}
      </p>
      <p className="text-xs text-gray-500 dark:text-gray-300 mb-5">
        Built with assets bought from -{' '}
        <a
          href="https://iknowkingrabbit.itch.io/"
          className="underline font-bold"
        >
          Aleksandr Makarov's Itch IO
        </a>{' '}
      </p>
      <p className="text-xs text-gray-500 dark:text-gray-300 mb-5">
        Written by Jason McDonald - jason.mcdonald11@gmail.com
      </p>
      <div className="mb-5">
        <StripeDonation />
      </div>
      <p className="text-xs text-gray-500 dark:text-gray-300 mb-5">
        Special Thanks To
      </p>
      <p className="text-xs text-gray-500 dark:text-gray-300 mb-5">
        Aaron Power - Meta and Rounding Out Game Logic
      </p>
      <p className="text-xs text-gray-500 dark:text-gray-300 mb-5">
        Tim Griffin - Music and Additional Programming
      </p>
      <p className="text-xs text-gray-500 dark:text-gray-300 mb-5">
        Music Attributions
      </p>
      <p className="text-xs text-gray-500 dark:text-gray-300">
        Celtic Impulse by Kevin MacLeod -  {' '}
        <a
          href="https://incompetech.filmmusic.io/song/3484-celtic-impulse"
          className="underline font-bold"
        >
          Link
        </a>{' '} - {' '}
        <a
          href="https://filmmusic.io/standard-license"
          className="underline font-bold"
        >
          License
        </a>{' '}
      </p>
    </BaseModal>
  )
}
