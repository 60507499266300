import { HealthTick } from './HealthTick'

type Props = {
    current: number,
    row: number[]
}

export const HealthRow = ({ current, row }: Props) => {
    return (
        <div className="flex justify-left mb-1">
            {row.map((_, i) => 
                <HealthTick key={i*1000000} alive={current > row[i]} />
            )}
        </div>
    )
}
