import { BaseModal } from './BaseModal'

import male_4 from "../../assets/avatars/male_4.png"

type Props = {
  round: number
  bestStreak: number
  isOpen: boolean
  handleClose: () => void
}

export const GameOverModal = ({ round, bestStreak, isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Game Over" isOpen={isOpen} handleClose={handleClose}>
        <div className="flex justify-left mx-8 my-6">
            <div className="scale-150 mt-2">
                <div className="scale-150">
                    <img className="scale-150" src={male_4} alt="" />
                </div>
            </div>
        </div>
        <p className="text-xs text-gray-500 dark:text-gray-300 px-1">
            {"So you've just about died, stab wound by the looks of it. You might be wondering why I'm standing here stealing your stuff. Gotta keep that Knifers supply chain flowing. Toodles from your favourite shop keep, Oont."}
        </p>
        <p className="text-xs text-gray-500 dark:text-gray-300 m-6">
            {"Round " + round}
        </p>
        <p className="text-xs text-gray-500 dark:text-gray-300">
            {"Best Round " + bestStreak}
        </p>
        <button
            type="button"
            className="mx-auto mt-8 flex items-center px-5 py-2 border border-transparent text-lg font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
            onClick={handleClose}
        >
            {"Ok"}
        </button>
    </BaseModal>
  )
}
