import { BaseModal } from './BaseModal'

type Props = {
  round: number
  nextRound: () => void
  visitStore: () => void
  isOpen: boolean
  handleClose: () => void
}

export const NextRoundModal = ({ round, nextRound, visitStore, isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Next Round" isOpen={isOpen} handleClose={handleClose}>
        <p className="text-xs text-gray-500 dark:text-gray-300 m-6">
            {"Round " + round + " complete."}
        </p>
        <p className="text-xs text-gray-500 dark:text-gray-300">
            {" Are you ready for the next round?"}
        </p>

        <button
            type="button"
            className="mx-auto mt-8 flex items-center px-5 py-2 border border-transparent text-lg font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
            onClick={nextRound}
        >
            {"Next Round"}
        </button>

        <button
            type="button"
            className="mx-auto mt-8 flex items-center px-5 py-2 border border-transparent text-lg font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
            onClick={visitStore}
        >
            {"Visit Store"}
        </button>
    </BaseModal>
  )
}
