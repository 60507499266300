import { BattleState } from '../../lib/localStorage'
import { MAX_CHALLENGES } from '../../constants/settings'
import { CompletedRow } from './CompletedRow'
import { CurrentRow } from './CurrentRow'
import { EmptyRow } from './EmptyRow'
import { getGuessValue } from '../../lib/statuses'
 
type Props = {
  guesses: string[]
  guessOrder: string[]
  currentGuess: string,
  battleState: BattleState,
  chooseAction: () => void,
}

export const Grid = ({ guesses, guessOrder, currentGuess, battleState, chooseAction }: Props) => {
  const empties =
    guesses.length < MAX_CHALLENGES - 1
      ? Array.from(Array(MAX_CHALLENGES - 1 - guesses.length))
      : []
  
  const actionClick = (player: boolean) => {
    if (!player) {
      return
    }

    chooseAction()
  }

  return (
    <div className="pb-2">
      {guesses.map((guess, i) => (
        <CompletedRow key={i} guess={guess} order={guessOrder[i]} guessValue={getGuessValue(guess, guesses.slice(0, i))} />
      ))}
      {guesses.length < MAX_CHALLENGES && <CurrentRow guess={currentGuess} battleState={battleState} actionClick={actionClick} />}
      {empties.map((_, i) => (
        <EmptyRow key={i} />
      ))}
    </div>
  )
}
