import classnames from 'classnames'

type Props = {
    value: string,
    onClick: (value: string) => void,
}

export const BattleAction = ({ value, onClick }: Props) => {
    const classes = classnames(
        'w-14 h-14 border-solid border-2 flex items-center justify-center mx-6 text-3xl font-bold rounded dark:text-white'
    )

    const handleClick = () => {
        onClick(value)
    }

    return (
       <button
            className={classes}
            onClick={handleClick}
            >
                <div className='scale-150'>
                    <img className='scale-150' alt="" src={value} />
                </div>
        </button>
    )
}
