import { ShieldRow } from './ShieldRow'
import { ShieldTick } from './ShieldTick'

type Props = {
    total: number
}

const getRows = (total: number) => {
    let rowArray = []
    let currentRow: number[] = []

    for (let i = 0; i < total; i++) {
        if (i > 0 && i % 10 === 0) {
            rowArray.push(currentRow)
            currentRow = []
        }

        currentRow.push(i)
    }

    if (currentRow.length > 0) {
        rowArray.push(currentRow)
    }

    return rowArray
}

export const CharacterShields = ({ total }: Props) => {
    const rowArray = getRows(total)

    if (total > 20) {
        return (
            <div className="dark:text-white mt-1 flex text-xs">
                <div className='py-1' >
                    <ShieldTick />  
                </div>
                {total}
            </div>
        )
    }

    return (
        <div className="dark:text-white mt-1">
            {rowArray.map((_, i) => 
                <ShieldRow key={i} row={rowArray[i]} />
            )}
        </div>
    )
}
