import { BaseModal } from './BaseModal'
import { Alert } from '../alerts/Alert'
import { unit } from '../../lib/localStorage'
import { CharacterCell } from "../battle/CharacterCell"
import { Shelf } from "../store/Shelf"
import { purchaseData } from '../store/Items'
import { useState } from 'react'

type Props = {
  unit: unit
  isOpen: boolean
  handleClose: () => void
  equip: (id: string) => void
  unequip: (id: string) => void
}

export const CharacterProfileModal = ({ unit, isOpen, handleClose, equip, unequip }: Props) => {

  const [loaded, setLoaded] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [showAlert, setShowAlert] = useState(false)
  const [equipped, setEquipped] = useState(unit.equipped)

  if (!loaded && unit.equipped.length > 0) {
    setEquipped(unit.equipped)
    setLoaded(true)
  }

  const handleEquip = (id: string) => {
    if (unit.name === 'enemy') {
      return
    }

    if (equipped.includes(id)) {
      setAlertMessage("Already Equipped")
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 1000)
      return
    }

    if (equipped.length >= 3) {
      setAlertMessage("Only 3 Items can be equipped at one time")
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 1000)
      return
    }

    setEquipped([...equipped, id])
    equip(id)
  }

  const handleUnequip = (id: string) => {
    setEquipped(equipped.filter((item) => id !== item))
    unequip(id)
  }

  return (
    <BaseModal title="Profile" isOpen={isOpen} handleClose={handleClose}>
      <div className="flex justify-center mb-1 mt-4">
          <CharacterCell unit={unit} animation={""} stopAnimation={() => null} onClick={() => null} />
        </div>
        {unit.name === 'player' &&
          <Shelf unit={unit} title='Equipped' items={equipped} select={(data: purchaseData) => handleUnequip(data.id)} />
        }
        <Shelf unit={unit} title='Items' items={unit.items} select={(data: purchaseData) => handleEquip(data.id)} /> 
        <p className="text-xs text-gray-500 dark:text-gray-300 mt-16">
          {"Level : " + unit.level}
        </p>
        <p className="text-xs text-gray-500 dark:text-gray-300 mt-4">
          {"HP : " + unit.current + " / " + unit.total}
        </p>
        <p className="text-xs text-gray-500 dark:text-gray-300 mt-4">
          {"Shields : " + unit.shields}
        </p>
        <p className="text-xs text-gray-500 dark:text-gray-300 mt-4">
          {"Medicine : " + unit.bandages}
        </p>
        <p className="text-xs text-gray-500 dark:text-gray-300 mt-4">
          {"Afflictions"}
        </p>
        {unit.afflictions.map((item, i) => (
          <p key={i} className="text-xs text-gray-500 dark:text-gray-300 mt-4">
            {item}
          </p>
        ))}
        <Alert
          message={alertMessage}
          isOpen={showAlert}
      />
    </BaseModal>
  )
}
