import { ShieldTick } from './ShieldTick'

type Props = {
    row: number[]
}

export const ShieldRow = ({row }: Props) => {
    return (
        <div className="flex justify-left mb-1">
            {row.map((_, i) => 
                <ShieldTick key={i} />
            )}
        </div>
    )
}
