import sword from '../assets/dagger.png'
import default_hero from "../assets/avatars/male_1.png"
import default_villain from "../assets/enemies/male_1.png"

import enemy_1 from "../assets/enemies/male_1.png"
import enemy_2 from "../assets/enemies/male_2.png"
import enemy_3 from "../assets/enemies/male_3.png"
import enemy_4 from "../assets/enemies/female_1.png"
import enemy_5 from "../assets/enemies/male_4.png"
import enemy_6 from "../assets/enemies/female_2.png"
import enemy_7 from "../assets/enemies/male_5.png"
import enemy_8 from "../assets/enemies/male_6.png"
import enemy_9 from "../assets/enemies/male_7.png"
import enemy_10 from "../assets/enemies/female_3.png"
import enemy_11 from "../assets/enemies/female_4.png"

const ENEMIES = [ 
    enemy_1, enemy_2, enemy_3, enemy_4, enemy_5, enemy_6, enemy_7,
    enemy_8, enemy_9, enemy_10, enemy_11     
]

const ITEMS = [
    'VampireCloak',
    'PoisonDagger',
    'JesterHat'
]

const EMPTY_STRING_ARRAY : string[] = []

export const BASE_PLAYER = {
    name: "player",
    level: 1,
    current: 16,
    total: 16,
    shields: 0,
    bandages: 2,
    action: sword,
    avatar: default_hero,
    items: EMPTY_STRING_ARRAY,
    equipped: EMPTY_STRING_ARRAY,
    afflictions: EMPTY_STRING_ARRAY,
    poisonCombo: 0
}

export const BASE_ENEMY = {
    name: "enemy",
    level: 1,
    current: 10,
    total: 10,
    shields: 0,
    bandages: 1,
    action: sword,
    avatar: default_villain,
    items: EMPTY_STRING_ARRAY,
    equipped: EMPTY_STRING_ARRAY,
    afflictions: EMPTY_STRING_ARRAY,
    poisonCombo: 0
}

export const getRandomEnemy = () => {
    return ENEMIES[Math.floor(Math.random() * ENEMIES.length)]
}

export const createNewEnemy = (round: number) => {
    let increase = ((round - (round % 5)) / 5) * 3.0
    let extraShields = ((round - (round % 10)) / 10) * 2.0
    let level = getEnemyLevel(round)
    let items = EMPTY_STRING_ARRAY
    let equipped = EMPTY_STRING_ARRAY

    let randomValue = Math.floor(Math.random() * 100)

    if ((round > 10 && randomValue > 50) || round > 20) { 
        let item = ITEMS[Math.floor(Math.random() * ITEMS.length)]
        items = [item]
        equipped = [item]

        if (round > 50) {
            let item2 = ITEMS[Math.floor(Math.random() * ITEMS.length)]

            if (!items.includes(item2)) {
                items.push(item2)
                equipped.push(item2)
            }
        }
    }
 
    return {
        ...BASE_ENEMY,
        current: BASE_ENEMY.current + increase,
        total: BASE_ENEMY.total + increase,
        shields: BASE_ENEMY.shields + extraShields,
        level: BASE_ENEMY.level + level,
        avatar: getRandomEnemy(),
        items: items,
        equipped: equipped
    }
}

const getEnemyLevel = (round: number) => {
    if (round < 20) {
        return (round - (round % 15)) / 15
    }

    let base = (round - (round % 15)) / 15
    let scalar = (round - (round % 25)) / 25

    return base + scalar
}