import { useState, useEffect } from 'react'
import { Alert } from './components/alerts/Alert'
import { Banner } from './components/banner/Banner'
import { BattleRow } from './components/battle/BattleRow'
import { Menu } from './Menu'
import { Store } from './components/store/Store'
import { purchaseData } from './components/store/Items'
import { Wordle } from './Wordle'
import { AboutModal } from './components/modals/AboutModal'
import { ActionModal } from './components/modals/ActionModal'
import { InfoModal } from './components/modals/InfoModal'
import { NextRoundModal } from './components/modals/NextRoundModal'
import { CharacterProfileModal } from './components/modals/CharacterProfileModal'
import { GameOverModal } from './components/modals/GameOverModal'
import { AudioModal } from "./components/modals/AudioModal"
import {
  ABOUT_GAME_MESSAGE,
} from './constants/strings'
import { getWordFromSeed } from './lib/words'
import { chooseWord, chooseAiAction } from './lib/ai'
import {
  loadBattleStateFromLocalStorage,
  loadBestStreakFromLocalStorage,
  loadGameStateFromLocalStorage,
  loadRandomSeedFromLocalStorage,
  saveBattleState,
  saveGameStateToLocalStorage,
  saveStreak,
  unit
} from './lib/localStorage'
import {
  processAttack,
  processShields,
  processHeal,
  processJester,
  processReflectiveShield,
  getUpdate
} from './lib/battleLogic'
import { BASE_PLAYER, BASE_ENEMY, createNewEnemy } from './constants/units'
import sword from './assets/dagger.png'
import poison_sword from './assets/store/PoisonDagger.png'
import shield from './assets/shield_new.png'
import medicine from './assets/store/Medicine.png'
import jester from './assets/store/JesterHat.png'
import reflect from "./assets/store/ReflectiveShield.png"

import './App.css'
import { getGuessValue, getUnlockableLetter, getCorrectLetterGuesses } from './lib/statuses'
import { MAX_CHALLENGES } from './constants/settings'

// const ALERT_TIME_MS = 2000

function App() {
  const [bestStreak, setBestStreak] = useState<number>(loadBestStreakFromLocalStorage())
  const [seed, setSeed] = useState<number>(loadRandomSeedFromLocalStorage())
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage()
    if (loaded?.solution !== getWordFromSeed(seed).solution) {
      return []
    }
    
    return loaded.guesses
  })
  const [guessOrder, setGuessOrder] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage()

    if (loaded?.solution !== getWordFromSeed(seed).solution) {
      return []
    }
    
    return loaded.guessOrder
  })

  const [inBattle, setInBattle] = useState(false)
  const [inStore, setInStore] = useState(false)

  const [animating, setAnimating] = useState(false)
  const [animation, setAnimation] = useState({})

  const [round, setRound] = useState<number>(() => {
    const loaded = loadBattleStateFromLocalStorage()

    return loaded ? loaded.round : 0
  })
  const [roundStart, setRoundStart] = useState(false)

  const [money, setMoney] = useState<number>(() => {
    const loaded = loadBattleStateFromLocalStorage()

    return loaded ? loaded.money : 0
  })
  const [playerTurn, setPlayerTurn] = useState<boolean>(() => {
    const loaded = loadBattleStateFromLocalStorage()

    return loaded ? loaded.playerTurn : false
  })
  const [player, setPlayer] = useState<unit>(() => {
    const loaded = loadBattleStateFromLocalStorage()

    return loaded ? loaded.player : BASE_PLAYER as unit
  })
  const [enemy, setEnemy] = useState<unit>(() => {
    const loaded = loadBattleStateFromLocalStorage()

    return loaded ? loaded.enemy : BASE_ENEMY as unit
  })

  const [avatar, setAvatar] = useState<string>(() => {
    const loaded = loadBattleStateFromLocalStorage()

    return loaded ? loaded.player.avatar : BASE_PLAYER.avatar
  })
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(true)
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)
  const [isActionModalOpen, setIsActionModalOpen ] = useState(false)
  const [isNextRoundModalOpen, setIsNextRoundModalOpen] = useState(false)
  const [isCharacterPofileOpen, setIsCharacterProfileOpen] = useState(false)
  const [isGameOverModalOpen, setIsGameOverModalOpen] = useState(false)
  const [isAudioModalOpen, setIsAudioModalOpen] = useState(false)

  const [selectedCharacter, setSelectedCharacter] = useState<unit>(BASE_PLAYER)

  const [missedWord, setMissedWord] = useState("")
  const [isMissedWordOpen, setIsMissedWordOpen] = useState(false)
  const [moneyEarned, setMoneyEarned] = useState("")
  const [isMoneyEarnedOpen, setIsMoneyEarnedOpen] = useState(false)

  const [music] = useState(new Audio('celtic.mp3'));
  const [playerAttackSound] = useState(new Audio('attack1.wav'))
  const [enemyAttackSound] = useState(new Audio('attack2.wav'))
  const [winSound] = useState(new Audio('win.wav'))

  const [playing, setPlaying] = useState(false);
  const [playSounds, setPlaySounds] = useState(false);

  useEffect(() => {
      playing ? music.play() : music.pause();
    },[playing, music]);

  useEffect(() => {
    music.addEventListener('ended', () => music.play());
  }, []);

  useEffect(() => {
    checkBattleConditions()
    saveGameStateToLocalStorage({ seed, guesses, guessOrder, solution: getWordFromSeed(seed).solution })
    saveBattleState({ playerTurn, round, money, player, enemy })
    saveStreak(bestStreak)

    if (!playerTurn && !isNextRoundModalOpen && 
      !guesses.includes(getWordFromSeed(seed).solution) && !animating && 
      guesses.length < MAX_CHALLENGES && player.current > 0 && enemy.current > 0) {
      setTimeout(() => {
        handleEnemyGuess()
      }, 1500)
    }
  }, [guesses, animating])

  const startGame = () => {
    setInBattle(true)
    setPlayerTurn(true)
    setGuessOrder([])
    setGuesses([])
    setMoney(30)
    setRound(0)
    setPlayer({
      ...BASE_PLAYER,
      avatar: avatar
    })
    setEnemy(BASE_ENEMY)

  }

  const continueGame = () => {
    setInBattle(true)
  }

  const chooseAction = () => {
    setIsActionModalOpen(true)
  }

  const handleActionSelection = (value: string) => {
    setPlayer({
      ...player,
      action: value
    })
    setIsActionModalOpen(false)
  }

  const handleAttack = (guessValue: number, failedRound: boolean) => {
    let updates = []
    if (playerTurn) {
      updates = processAttack(player, enemy, guessValue)
      if (guessValue > 0) {
        setAnimation({ enemy: "attack" })
        setAnimating(true)
      }
    } else {
      updates = processAttack(enemy, player, guessValue)
      if (guessValue > 0) {
        setAnimation({ player: "attack" })
        setAnimating(true)
      }
    }

    setPlayer({
      ...player,
      shields: getUpdate('player', 'shields', updates, player.shields),
      current: Math.max(0, getUpdate('player', 'current', updates, player.current) - (failedRound ? 2 : 0)),
      afflictions: getUpdate('player', 'afflictions', updates, player.afflictions),
      poisonCombo: getUpdate('player', 'poisonCombo', updates, player.poisonCombo)
    })

    setEnemy({
      ...enemy,
      shields: getUpdate('enemy', 'shields', updates, enemy.shields),
      current: Math.max(0, getUpdate('enemy', 'current', updates, enemy.current) - (failedRound ? 2 : 0)),
      afflictions: getUpdate('enemy', 'afflictions', updates, enemy.afflictions),
      poisonCombo: getUpdate('enemy', 'poisonCombo', updates, enemy.poisonCombo)
    })
  }

  const handleShields = (guessValue: number, failedRound: boolean) => {
    let updates = []
    if (playerTurn) {
      updates = processShields(player, guessValue)
      if (guessValue >= 2) {
        setAnimation({ player: "shield" })
        setAnimating(true)
      }
    } else {
      updates = processShields(enemy, guessValue)
      if (guessValue >= 2) {
        setAnimation({ enemy: "shield" })
        setAnimating(true)
      }
    }

    setPlayer({
      ...player,
      shields: playerTurn ? getUpdate('player', 'shields', updates, player.shields) : player.shields,
      current: Math.max(0, getUpdate('player', 'current', updates, player.current) - (failedRound ? 2 : 0)),
      poisonCombo: getUpdate('player', 'poisonCombo', updates, player.poisonCombo)
    })

    setEnemy({
      ...enemy,
      shields: !playerTurn ? getUpdate('enemy', 'shields', updates, enemy.shields) : enemy.shields,
      current: Math.max(0, getUpdate('enemy', 'current', updates, enemy.current) - (failedRound ? 2 : 0)),
      poisonCombo: getUpdate('enemy', 'poisonCombo', updates, enemy.poisonCombo)
    })
  }

  const handleHeal = (guessValue: number, failedRound: boolean) => {
    let updates = []

    if (playerTurn) {
      updates = processHeal(player, guessValue)
      if (guessValue > 0) {
        setAnimation({ player: "heal" })
        setAnimating(true)
      }
    } else {
      updates = processHeal(enemy, guessValue)
      if (guessValue > 0) {
        setAnimation({ enemy: "heal" })
        setAnimating(true)
      }
    }

    let playerCurrent = playerTurn ? getUpdate('player', 'current', updates, player.current) : player.current
    let enemyCurrent = !playerTurn ? getUpdate('enemy', 'current', updates, enemy.current) : enemy.current

    setPlayer({
      ...player,
      current: Math.max(0, playerCurrent - (failedRound ? 2 : 0)),
      bandages: playerTurn ? getUpdate('player', 'bandages', updates, player.bandages) : player.bandages,
      action: playerTurn ? getUpdate('player', 'action', updates, player.action) : player.action,
      poisonCombo: getUpdate('player', 'poisonCombo', updates, player.poisonCombo)
    })

    setEnemy({
      ...enemy,
      current: Math.max(0, enemyCurrent - (failedRound ? 2 : 0)),
      bandages: !playerTurn ? getUpdate('enemy', 'bandages', updates, enemy.bandages) : enemy.bandages,
      action: !playerTurn ? getUpdate('enemy', 'action', updates, enemy.action) : enemy.action,
      poisonCombo: getUpdate('enemy', 'poisonCombo', updates, enemy.poisonCombo)
    })
  }

  const handleJester = (guessValue: number, failedRound: boolean) => {
    let updates = []
    if (playerTurn) {
      updates = processJester(player, enemy, guessValue)

      if (guessValue > 0) {
        setAnimation({ player: "attack", enemy: "attack" })
        setAnimating(true)
      }
    } else {
      updates = processJester(enemy, player, guessValue)

      if (guessValue > 0) {
        setAnimation({ player: "attack", enemy: "attack" })
        setAnimating(true)
      }
    }

    setPlayer({
      ...player,
      shields: getUpdate('player', 'shields', updates, player.shields),
      current: Math.max(0, getUpdate('player', 'current', updates, player.current) - (failedRound ? 2 : 0)),
      afflictions: getUpdate('player', 'afflictions', updates, player.afflictions),
      poisonCombo: getUpdate('player', 'poisonCombo', updates, player.poisonCombo)
    })

    setEnemy({
      ...enemy,
      shields: getUpdate('enemy', 'shields', updates, enemy.shields),
      current: Math.max(0, getUpdate('enemy', 'current', updates, enemy.current) - (failedRound ? 2 : 0)),
      afflictions: getUpdate('enemy', 'afflictions', updates, enemy.afflictions),
      poisonCombo: getUpdate('enemy', 'poisonCombo', updates, enemy.poisonCombo)
    })
  }

  const handleReflectiveShield = (guessValue: number, failedRound: boolean) => {
    let updates = []
    if (playerTurn) {
      updates = processReflectiveShield(player, guessValue)
      if (guessValue >= 4) {
        setAnimation({ player: "shield" })
        setAnimating(true)
      }
    } else {
      updates = processReflectiveShield(enemy, guessValue)
      if (guessValue >= 4) {
        setAnimation({ enemy: "shield" })
        setAnimating(true)
      }
    }

    setPlayer({
      ...player,
      shields: playerTurn ? getUpdate('player', 'shields', updates, player.shields) : player.shields,
      current: Math.max(0, getUpdate('player', 'current', updates, player.current) - (failedRound ? 2 : 0)),
      afflictions: playerTurn ? getUpdate('player', 'afflictions', updates, player.afflictions) : player.afflictions,
      poisonCombo: getUpdate('player', 'poisonCombo', updates, player.poisonCombo)
    })

    setEnemy({
      ...enemy,
      shields: !playerTurn ? getUpdate('enemy', 'shields', updates, enemy.shields) : enemy.shields,
      current: Math.max(0, getUpdate('enemy', 'current', updates, enemy.current) - (failedRound ? 2 : 0)),
      afflictions: !playerTurn ? getUpdate('enemy', 'afflictions', updates, enemy.afflictions) : enemy.afflictions,
      poisonCombo: getUpdate('enemy', 'poisonCombo', updates, enemy.poisonCombo)
    })
  }

  const processLuckyRoll = (guess: string): string => {
    let randomValue = Math.floor(Math.random() * 100)

    if (randomValue > 95) {
      let unlockIndex = getUnlockableLetter([...guesses, guess])

      return guess.substring(0, unlockIndex) + getWordFromSeed(seed).solution.split('')[unlockIndex] + guess.substring(unlockIndex + 1)
    }

    return guess
  }

  const handleGuess = (guess: string, guessValue: number) => {
    if (!playerTurn || animating || isNextRoundModalOpen || inStore || isInfoModalOpen || isAboutModalOpen || isCharacterPofileOpen || guesses.length >= MAX_CHALLENGES) return

    let level = playerTurn ? player.level : enemy.level
    let standardGuess = guess
    let standardGuessValue = guessValue
    if (playerTurn && player.equipped.includes('LuckyRoll')) {
      standardGuess = processLuckyRoll(guess)
      standardGuessValue = getGuessValue(standardGuess, guesses)
    }
    let correctLettersValue = getCorrectLetterGuesses(standardGuess, guesses)

    let roundFinishedButNotSolved = guesses.length + 1 === 6 && standardGuess.toUpperCase() !== getWordFromSeed(seed).solution

    handleAction(player.action, standardGuessValue * level, correctLettersValue * level, roundFinishedButNotSolved)

    if (roundStart && playerTurn && player.equipped.includes('SpeedyBoots')) {
      setRoundStart(false)
    } else {
      setPlayerTurn(!playerTurn)
    }
    setGuessOrder([...guessOrder, "player"])
    setGuesses([...guesses, standardGuess])

    if (playSounds) {
      if (playerTurn) {
        playerAttackSound.play() 
      } else {
        enemyAttackSound.play()
      }
    }

    if ([...guesses, guess.toUpperCase()].length === MAX_CHALLENGES || standardGuess.toUpperCase() === getWordFromSeed(seed).solution) {
      setTimeout(() => {
        handleRoundFinish(standardGuess.toUpperCase() === getWordFromSeed(seed).solution)
      }, 1000)
    } else {
      setTimeout(() => {
        checkBattleConditions()
      }, 500)
    }
  }

  const handleEnemyGuess = () => {
    if (animating || guesses.length >= MAX_CHALLENGES || isNextRoundModalOpen || inStore || isInfoModalOpen || isAboutModalOpen || isCharacterPofileOpen) return

    let aiAction = chooseAiAction(enemy, guesses)
    setEnemy((prevState) => { 
      return {
        ...prevState, action: aiAction 
      }
    })
    // setEnemy({
    //   ...enemy,
    //   action: aiAction
    // })

    setTimeout(() => {
      let aiGuess = chooseWord(guesses)
      let correctLettersValue = getCorrectLetterGuesses(aiGuess, guesses)

      let roundFinishedButNotSolved = guesses.length + 1 === MAX_CHALLENGES && aiGuess.toUpperCase() !== getWordFromSeed(seed).solution

      handleAction(aiAction, getGuessValue(aiGuess.toUpperCase(), guesses) * enemy.level, correctLettersValue * enemy.level, roundFinishedButNotSolved)

      setPlayerTurn(true)
      setGuessOrder([...guessOrder, "enemy"])
      setGuesses([...guesses, aiGuess.toUpperCase()])

      if ([...guesses, aiGuess.toUpperCase()].length === MAX_CHALLENGES || aiGuess.toUpperCase() === getWordFromSeed(seed).solution) {
        setTimeout(() => {
          handleRoundFinish(aiGuess.toUpperCase() === getWordFromSeed(seed).solution)
        }, 1000)
      } else {
        checkBattleConditions()
      }

      if (playSounds) {
        if (playerTurn) {
          playerAttackSound.play() 
        } else {
          enemyAttackSound.play()
        }
      }
    }, 500)
  }

  const handleAction = (action: string, guessValue: number, correctLettersValue: number, failedRound: boolean) => {
    // let action = playerTurn ? player.action : enemy.action

    console.log({
      playerPoison: player.poisonCombo,
      enemyPoison: enemy.poisonCombo
    })

    if (action === sword || action === poison_sword) {
      handleAttack(guessValue, failedRound)
    } else if (action === shield) {
      handleShields(correctLettersValue, failedRound)
    } else if (action === medicine) {
      handleHeal(guessValue, failedRound)
    } else if (action === jester) {
      handleJester(guessValue, failedRound)
    } else if (action === reflect) {
      handleReflectiveShield(guessValue, failedRound)
    }
  }

  const handleRoundFinish = (solved: boolean) => {
    if (!solved) {
      setMissedWord("The word was " + getWordFromSeed(seed).solution)
      setIsMissedWordOpen(true)
      setTimeout(() => {
        setIsMissedWordOpen(false)
      }, 2000)
    } else if (playerTurn) {
      let multiplier = player.equipped.includes('GoldPot') ? 2 : 1
      let earnings = (7 - guesses.length) * 10
      setMoney(money + (earnings * multiplier))
      setMoneyEarned("You Earned $" + (earnings * multiplier))
      setIsMoneyEarnedOpen(true)
      setTimeout(() => {
        setIsMoneyEarnedOpen(false)
      }, 2000)
    }

    setTimeout(() => {
      setSeed(Math.floor(Math.random() * 1000000))
      setGuessOrder([])
      setGuesses([])

      checkBattleConditions()
    }, 1500)
  }

  const checkBattleConditions = () => {
    if (player.current <= 0) {
      setTimeout(() => {
        setInBattle(false)
      }, 1500)
      if (round > bestStreak) {
        saveStreak(round)
        setBestStreak(round)
      }
      setIsGameOverModalOpen(true)
      setPlayer({
        ...BASE_PLAYER,
        current: 0,
      })
      return
    }

    if (enemy.current <= 0) {
      playSounds && winSound.play()
      let multiplier = player.equipped.includes('GoldPot') ? 2 : 1
      setPlayerTurn(true)
      setMoney(money + (10 * multiplier))
      setMoneyEarned("You Earned $" + (10 * multiplier))
      setIsMoneyEarnedOpen(true)
      setTimeout(() => {
        setIsMoneyEarnedOpen(false)
      }, 2000)
      setIsNextRoundModalOpen(true)
      setGuessOrder([])
      setGuesses([])

      setRound(round + 1)
      setRoundStart(true)
      setEnemy(createNewEnemy(round + 1))
      if (!isMissedWordOpen) {
        setMissedWord("The word was " + getWordFromSeed(seed).solution)
        setIsMissedWordOpen(true)
        setTimeout(() => {
          setIsMissedWordOpen(false)
        }, 2000)
      }
    }
  }

  const startNextRound = () => {
    setSeed(Math.floor(Math.random() * 1000000))
    setGuessOrder([])
    setGuesses([])
    setIsNextRoundModalOpen(false)
  }

  const updateAvatar = (value: string) => {
    setAvatar(value)
  }

  const stopAnimation = () => {
    setAnimation({})
    setAnimating(false)
  }

  const visitStore = () => {
    if (player.equipped.includes('VampireCloak')) {
      setPlayer({
        ...player,
        current: player.current - 2 > 1 ? player.current - 2 : 1,
      })
    }

    setInStore(true)
    setIsNextRoundModalOpen(false)
    setSeed(Math.floor(Math.random() * 1000000))
    setGuessOrder([])
    setGuesses([])
  }

  const leaveStore = () => {
    setInStore(false)
    setIsNextRoundModalOpen(true)
  }

  const healPlayer = () => {
    setPlayer({
      ...player,
      current: player.total
    })
    setMoney(money - 10)
  }

  const purchaseItem = (data: purchaseData) => {
    setMoney(money - data.price)
    if (data.id === 'Medicine') {
      setPlayer({
        ...player,
        bandages: player.bandages + 3
      })
      return 
    }

    if (player.items.includes(data.id.substring(0, data.id.length - 1))) {
      setPlayer({
        ...player,
        items: [...player.items.filter((item) => item !== data.id.substring(0, data.id.length - 1)), data.id]
      })
      return
    }

    setPlayer({
      ...player,
      items: [...player.items, data.id]
    })
  }

  const upgradeStat = (data: purchaseData) => {
    if (data.id === 'UpgradeHealth') {
      setPlayer({
        ...player,
        current: player.current + 1,
        total: player.total + 1
      })
      setMoney(money - data.price)
    } else if (data.id === 'LevelUp') {
      setPlayer({
        ...player,
        level: player.level + 1
      })
      setMoney(money - data.price)
    }
  }

  const curePlayer = () => {
    setPlayer({
      ...player,
      afflictions: player.afflictions.includes('vampire') ? ['vampire'] : []
    })
    setMoney(money - 10)
  }

  const viewCharacter = (unit: unit) => {
    setSelectedCharacter(unit)
    setIsCharacterProfileOpen(true)
  }

  const handleEquip = (id: string) => {
    setPlayer({
      ...player,
      equipped: [...player.equipped, id],
      action: player.action === sword && id === 'PoisonDagger' ? poison_sword : player.action
    })
  }

  const handleUnEquip = (id: string) => {
    let equipped = player.equipped.filter((item) => item !== id)

    let action = player.action
    if (id === 'JesterHat' && player.action === jester) {
      action = player.equipped.includes('PoisonDagger') ? poison_sword : sword
    } else if (id === 'PoisonDagger' && player.action === poison_sword) {
      action = sword
    }
    
    setPlayer({
      ...player,
      equipped: equipped,
      action: action
    })
  }

  return (
    <div className="py-4 max-w-7xl mx-auto sm:px-6 lg:px-8">
      <Banner audioOn={playing || playSounds} showAudioSettings={() => setIsAudioModalOpen(true)} />
      {inBattle ? 
        (!inStore ?
          <>
            <BattleRow 
              player={player}
              enemy={enemy}
              animation={animation}
              stopAnimation={stopAnimation}
              characterClick={viewCharacter}
            />
            <Wordle 
              gameState={{ seed, guesses, guessOrder, solution: getWordFromSeed(seed).solution }}
              battleState={ { playerTurn: playerTurn, round, money, player: player, enemy: enemy } } 
              animating={animating || !playerTurn}
              chooseAction={chooseAction} 
              processGuess={handleGuess}
            />
          </> :
          <>
            <Store 
              battleState={ { playerTurn: playerTurn, round, money, player: player, enemy: enemy } } 
              heal={healPlayer}
              purchase={purchaseItem}
              upgrade={upgradeStat}
              cure={curePlayer}
              leave={leaveStore} 
            />
          </>) :
        <Menu 
          battleState={{ playerTurn: playerTurn, round, money, player: player, enemy: enemy }} 
          play={startGame} 
          continueGame={continueGame}
          updateAvatar={updateAvatar}/>
      }
      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />
      <ActionModal 
        battleState={{ playerTurn, round, money, player, enemy }} 
        isOpen={isActionModalOpen}
        handleClose={() => setIsActionModalOpen(false)}
        selectAction={handleActionSelection}
      />
      <AboutModal 
        isOpen={isAboutModalOpen}
        handleClose={() => setIsAboutModalOpen(false)}
      />
      <CharacterProfileModal 
        unit={selectedCharacter}
        isOpen={isCharacterPofileOpen}
        handleClose={() => setIsCharacterProfileOpen(false)}
        equip={handleEquip}
        unequip={handleUnEquip}
      />
      <NextRoundModal 
        round={round}
        visitStore={visitStore}
        nextRound={startNextRound}
        isOpen={isNextRoundModalOpen}
        handleClose={startNextRound}
      />
      <GameOverModal 
        round={round} 
        bestStreak={bestStreak} 
        isOpen={isGameOverModalOpen}
        handleClose={() => setIsGameOverModalOpen(false)}
      />
      <AudioModal 
        background={playing}
        sound={playSounds}
        setBackground={setPlaying}
        setSound={setPlaySounds}
        isOpen={isAudioModalOpen} 
        handleClose={() => setIsAudioModalOpen(false)} 
        />
      <Alert
          message={missedWord}
          isOpen={isMissedWordOpen}
      />
      <Alert
          variant='success'
          message={moneyEarned}
          isOpen={isMoneyEarnedOpen}
      />
      <button
        type="button"
        className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
        onClick={() => setIsAboutModalOpen(true)}
      >
        {ABOUT_GAME_MESSAGE}
      </button>
    </div>
  )
}

export default App
