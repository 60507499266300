import classnames from 'classnames'

export const ShieldTick = () => {
  const classes = classnames(
    'w-1 h-1 items-center justify-center mx-0.5 select-none bg-blue-500'
  )

  return (
      <div className={classes}>

      </div>
  )
}
