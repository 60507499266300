import classnames from 'classnames'

type Props = {
  alive?: boolean
}

export const HealthTick = ({
  alive
}: Props) => {
  const classes = classnames(
    'w-1 h-1 items-center justify-center mx-0.5',
    {
        'bg-green-500': alive,
        'bg-red-500': !alive,
    }
  )

  return (
      <div className={classes}>

      </div>
  )
}
