import {
    InformationCircleIcon, MusicNoteIcon, VolumeUpIcon, VolumeOffIcon
  } from '@heroicons/react/outline'
  import { useState } from 'react'
  import { InfoModal } from '../../components/modals/InfoModal'
  import {
    GAME_TITLE,
  } from '../../constants/strings'

  type Props = {
    audioOn: boolean,
    showAudioSettings: () => void
  }
  
  export const Banner = ({ audioOn, showAudioSettings } : Props) =>{
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  
    document.documentElement.classList.add('dark')
  
    return (
      <>
        <div className="flex w-90 mx-auto items-center mb-4">
          <h1 className="text-sm ml-2.5 grow font-bold dark:text-white">
            {GAME_TITLE}
          </h1>
          {audioOn ? 
            <VolumeUpIcon className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white" onClick={showAudioSettings}/> : 
            <VolumeOffIcon className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white" onClick={showAudioSettings}/>
          }
          <InformationCircleIcon
            className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white"
            onClick={() => setIsInfoModalOpen(true)}
          />
        </div>
        <InfoModal
          isOpen={isInfoModalOpen}
          handleClose={() => setIsInfoModalOpen(false)}
        />
      </>
    )
  }
  