import { BattleState } from '../../lib/localStorage'
import { MAX_WORD_LENGTH } from '../../constants/settings'
import { Cell } from './Cell'
import { ActionCell } from './ActionCell'

type Props = {
  guess: string,
  battleState: BattleState,
  actionClick: (player: boolean) => void,
}

export const CurrentRow = ({ guess, battleState, actionClick }: Props) => {
  const splitGuess = guess.split('')
    const emptyCells = Array.from(Array(MAX_WORD_LENGTH - splitGuess.length))

    return (
      <div className="flex justify-center mb-1">
        <ActionCell key={0} player={true} active={battleState.playerTurn} action={battleState.player.action} onClick={actionClick} />
        {splitGuess.map((letter, i) => (
          <Cell key={i+1} value={letter} />
        ))}
        {emptyCells.map((_, i) => (
          <Cell key={i+1} />
        ))}
        <ActionCell key={7} player={false} active={!battleState.playerTurn} action={battleState.enemy.action} onClick={actionClick} />
      </div>
    )
}
