import { CharacterCell } from './CharacterCell'
import { unit } from '../../lib/localStorage'


type Props = {
    player: unit
    enemy: unit
    animation: any,
    stopAnimation: () => void,
    characterClick: (unit: unit) => void,
}

export const BattleRow = ({ player, enemy, animation, stopAnimation, characterClick }: Props) => {

    return (
        <div className='flex justify-center mb-6'>
            <CharacterCell unit={player} animation={animation.player} stopAnimation={stopAnimation} onClick={characterClick} />
            <div className="w-6 h-20 flex items-center justify-center text-lg font-bold  dark:text-white">
                {"Vs"}
            </div>
            <CharacterCell unit={enemy} animation={animation.enemy} stopAnimation={stopAnimation} onClick={characterClick} />
        </div>
    )
}
