import classnames from 'classnames'

type Props = {
  player: boolean,
  active: boolean,
  action: string,
  guessValue?: number,
  order?: string,
  onClick: (id: boolean) => void,
}

export const ActionCell = ({ player, active, action, guessValue, order, onClick }: Props) => {
    const classes = classnames(
        'w-14 h-14 flex items-center justify-center mx-0.5 text-2xl font-bold rounded dark:text-white',
        {
            'border-solid border-2': active,

        }
    )
    const scoreClass = classnames(
        'w-14 h-14 flex items-center justify-center mx-0.5 text-2xl font-bold rounded',
        {
            'text-red-400': order === 'enemy',
            'text-green-400': order === 'player'
        }
    )

    const buildAction = (action?: string) => {
        return (
            <button className='h-7 w-7 mx-1 px-2 dark:stroke-white cursor-pointer' onClick={() => onClick(player)}>
                <div className='scale-150'>
                    <img className='scale-150' alt="" src={action} />
                </div>
            </button>
        )
    }

    return (active ? (
        <div className={classes}>
            {player ? 
                buildAction(action):
                buildAction(action)
            }
        </div>
    ) : (
        <div className={scoreClass}>
            {!player && guessValue}
        </div>
    ))
}
