import { HealthRow } from './HealthRow'
import { HealthTick } from './HealthTick'

type Props = {
    current: number,
    total: number
}

const getRows = (total: number) => {
    let rowArray = []
    let currentRow: number[] = []

    for (let i = 0; i < total; i++) {
        if (i > 0 && i % 10 === 0) {
            rowArray.push(currentRow)
            currentRow = []
        }

        currentRow.push(i)
    }

    if (currentRow.length > 0) {
        rowArray.push(currentRow)
    }

    return rowArray
}

export const CharacterHealth = ({ current, total }: Props) => {
    const rowArray = getRows(total)

    if (total > 30) {
        return (
            <div className="dark:text-white mt-1 flex text-xs">
                <div className='py-1' >
                    <HealthTick alive={true} />  
                </div>
                {current + "/" + total}
            </div>
        )
    }

    return (
        <div className="dark:text-white">
            {rowArray.map((_, i) => 
                <HealthRow key={i*100} current={current} row={rowArray[i]} />
            )}
        </div>
    )
}
