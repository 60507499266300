import sword from '../assets/dagger.png'
import poison_sword from '../assets/store/PoisonDagger.png'
import shield from '../assets/shield_new.png'
import jester from '../assets/store/JesterHat.png'
import heal from '../assets/store/Medicine.png'

import { getWordFromSeed, getWordsFromDiscoveredWord } from './words'
import { loadRandomSeedFromLocalStorage, unit } from './localStorage'


const hpRemainingPercentage = (unit: unit): number => {
    return Math.floor((unit.current / unit.total) * 100)
}

export const chooseAiAction = (unit: unit, guesses: string[]) => {
    const { score } = getSolutionScore(guesses)

    if (unit.items.includes('JesterHat') && hpRemainingPercentage(unit) > 65) {
        return jester
    }

    if (score >= 6) {
        let randomValue = Math.floor(Math.random() * 100)

        if (randomValue > 65) {
            return shield
        }
    }

    return unit.items.includes('PoisonDagger') || unit.items.includes('PoisonDagger2') ? poison_sword : sword
}


export const chooseWord = (guesses: string[]): string => {
    const { score, solutionMap } = getSolutionScore(guesses)

    let word = getStrategy(score)(guesses, getWordFromSolutionMap(solutionMap))

    return guesses.includes(word) ?
        getStrategy(score)(guesses, getWordFromSolutionMap(solutionMap)) : 
        word
}

const getSolutionScore = (guesses: string[]) => {
    const seed : number = loadRandomSeedFromLocalStorage()
    const solution = getWordFromSeed(seed).solution
    const splitSolution = solution.split('')

    const solutionScore = splitSolution.map((_) => 0)

    for (let pastGuess of guesses) {
        pastGuess.split('').forEach((letter, i) => {
            if (letter === splitSolution[i]) {
                solutionScore[i] = 2
            }
        })
    }

    guesses.forEach((pastGuess) => {
        pastGuess.split('').forEach((letter, i) => {
            if (solutionScore[i] === 2 || !splitSolution.includes(letter)) return

            const indexOfPresentChar = splitSolution.findIndex((x, index) => x === letter && solutionScore[index] === 0)

            if (indexOfPresentChar > -1 && solutionScore[indexOfPresentChar] === 0) {
                solutionScore[indexOfPresentChar] = 1
                return
            }
        })
    })

    return { score: solutionScore.reduce((partialSum, x) => partialSum + x, 0), solutionMap: solutionScore }
}

const getWordFromSolutionMap = (solutionMap: number[]) => {
    const seed : number = loadRandomSeedFromLocalStorage()
    const solution = getWordFromSeed(seed).solution
    const splitSolution = solution.split('')

    let discoveredSolution: string[] = []

    solutionMap.forEach((value, i) => {
        discoveredSolution.push(value === 2 ? splitSolution[i] : '')
    })

    return discoveredSolution
}

const getStrategy = (score: number) => {
    return allWordStrategy
}

const allWordStrategy = (guesses: string[], solutionMap: string[]): string => {
    return getWordsFromDiscoveredWord(guesses, solutionMap)
}