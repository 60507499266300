import { BattleState } from "../../lib/localStorage"
import { Shelf } from "./Shelf"

import { Cell } from '../grid/Cell'
import male_4 from "../../assets/avatars/male_4.png"
import { CharacterCell } from "../battle/CharacterCell"
import { useState } from "react"

import { purchaseData } from './Items'

type Props = {
	battleState: BattleState,
    heal: () => void,
    cure: () => void,
    purchase: (data: purchaseData) => void,
    upgrade: (data: purchaseData) => void,
    leave: () => void,
}

const SERVICES = [ 'Heal', 'Cure', 'UpgradeHealth', 'LevelUp' ]
const ITEMS = [ 'Medicine', 'PoisonDagger', 'VampireCloak', 'GoldPot', 'ReflectiveShield', 'LuckyRoll', 'JesterHat', 'SpeedyBoots' ]

const removeBoughtItems = (battleState: BattleState, items: string[]): string[] => {
    let availableItems = []

    // let hasPoisonDagger = battleState.player.items.includes('PoisonDagger')
    let hasPoisonDagger2 = battleState.player.items.includes('PoisonDagger2')

    for (let item of items) { 
        if (item === 'PoisonDagger' && hasPoisonDagger2) continue

        if (!battleState.player.items.includes(item)) {
            availableItems.push(item)
        }
    }

    return availableItems
}

const DEFAULT_ITEM = {
    id: "None", 
    title: "None", 
    purchaseText: "Purchase", 
    purchaseDescription: "Relax weary travelers, the night is long and full of knifers. No KNIFING at the Knifers Delight", 
    price: 0
}
 
export const Store = ({ battleState, heal, cure, purchase, upgrade, leave }: Props) => {

    const [selectedPurchase, setSelectedPurchase] = useState<purchaseData>(DEFAULT_ITEM)
    
    const purchaseClicked = () => {
        if (selectedPurchase.id === 'Heal') {
            attemptPurchase(selectedPurchase.price, heal)
        } else if (selectedPurchase.id === 'Cure') {
            attemptPurchase(selectedPurchase.price, cure)
        } else if (selectedPurchase.id === 'Medicine') {
            attemptPurchaseWithData(selectedPurchase.price, selectedPurchase.id, purchase)
        } else if (selectedPurchase.id === 'UpgradeHealth') {
            attemptPurchaseWithData(selectedPurchase.price, selectedPurchase.id, upgrade)
        } else if (selectedPurchase.id === 'LevelUp') {
            attemptPurchaseWithData(selectedPurchase.price, selectedPurchase.id, upgrade)
        } else if (selectedPurchase.id === 'PoisonDagger') {
            attemptPurchaseWithData(selectedPurchase.price, selectedPurchase.id, purchase)
        } else if (selectedPurchase.id === 'VampireCloak') {
            attemptPurchaseWithData(selectedPurchase.price, selectedPurchase.id, purchase)
        } else if (selectedPurchase.id === 'LuckyRoll') {
            attemptPurchaseWithData(selectedPurchase.price, selectedPurchase.id, purchase)
        } else if (selectedPurchase.id === 'JesterHat') {
            attemptPurchaseWithData(selectedPurchase.price, selectedPurchase.id, purchase)
        } else if (selectedPurchase.id === 'SpeedyBoots') {
            attemptPurchaseWithData(selectedPurchase.price, selectedPurchase.id, purchase)
        } else if (selectedPurchase.id === 'GoldPot') {
            attemptPurchaseWithData(selectedPurchase.price, selectedPurchase.id, purchase)
        } else if (selectedPurchase.id === 'PoisonDagger2') {
            attemptPurchaseWithData(selectedPurchase.price, selectedPurchase.id, purchase)
        } else if (selectedPurchase.id === 'ReflectiveShield') {
            attemptPurchaseWithData(selectedPurchase.price, selectedPurchase.id, purchase)
        }
    }

    const attemptPurchase = (cost: number, purchaseCall: () => void) => {
        if (battleState.money < cost) {
            setSelectedPurchase({
                ...selectedPurchase,
                purchaseDescription: "With what money? I see no cash."
            })
            return
        }

        purchaseCall()
        setSelectedPurchase(DEFAULT_ITEM)
    }

    const attemptPurchaseWithData = (cost: number, id: string, purchaseCall: (data: purchaseData) => void) => {
        if (battleState.money < cost) {
            setSelectedPurchase({
                ...selectedPurchase,
                purchaseDescription: "With what money? I see no cash."
            })
            return
        }

        if (battleState.player.items.includes(id)) {
            setSelectedPurchase({
                ...selectedPurchase,
                purchaseDescription: "Really you want to buy two of the same item? Get out of here, don't make me save you from yourself"
            })
            return
        }

        purchaseCall(selectedPurchase)
        setSelectedPurchase(DEFAULT_ITEM)
    }

	return (
	<div className="max-w-7xl mx-auto overflow-x-hidden sm:px-6 lg:px-8">
        <div className="flex justify-center mb-1 mt-4">
          <Cell value="K" status="correct" />
          <Cell value="N" status="correct" />
          <Cell value="I" status="correct" />
          <Cell value="F" status="correct"/>
          <Cell value="E" status="correct"/>
          <Cell value="R" status="correct"/>
          <Cell value="S" status="correct" />
          <Cell value="" />
        </div>
        <div className="flex justify-center mt-2">
          <Cell value="" />
          <Cell value="D" status="correct" />
          <Cell value="E" status="correct" />
          <Cell value="L" status="correct"/>
          <Cell value="I" status="correct"/>
          <Cell value="G" status="correct"/>
          <Cell value="H" status="correct"/>
          <Cell value="T" status="correct" />
        </div>
        <div className="flex justify-left px-5 mx-8 my-6">
            <div className="scale-150 mt-2">
                <div className="scale-150">
                    <img className="scale-150" src={male_4} alt="" />
                </div>
            </div>
        </div>
        <div className="flex justify-center mx-8 my-2">
            <p className="text-xs text-gray-500 dark:text-gray-300 mt-1">
                {selectedPurchase.purchaseDescription}
            </p>
        </div>
        {selectedPurchase.id !== "None" && (
            <>
                <div className="flex justify-center mx-8 mt-4">
                    <p className="text-xs text-gray-500 dark:text-gray-300 mt-1">
                        {selectedPurchase.title}
                    </p>
                </div>
                <button
                    type="button"
                    className="mx-auto mt-4 flex items-center px-2 py-2 border border-transparent font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
                    onClick={purchaseClicked}
                >
                    {selectedPurchase.purchaseText}
                </button>
            </>
        )}
        <div className="flex justify-center mt-1">
            <CharacterCell unit={battleState.player} animation={""} stopAnimation={() => null} onClick={() => null} />
        </div>
        <div className="flex justify-center mt-4">
            <p className="text-xs text-gray-500 dark:text-gray-300 mt-2">
                {"$" + battleState.money}
            </p>
        </div>
        <Shelf unit={battleState.player} title={"Services & Upgrades"} items={SERVICES} select={setSelectedPurchase} />
        <Shelf unit={battleState.player} title={"Items"} items={removeBoughtItems(battleState, ITEMS)} select={setSelectedPurchase} />
        <button
            type="button"
            className="mx-auto mt-6 flex items-center px-2 py-2 border border-transparent font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
            onClick={leave}
        >
            {"Leave Store"}
        </button>
	</div>
	)
}
  