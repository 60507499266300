import { useState } from 'react'
import classnames from 'classnames'
import { CharacterStatuses } from "./CharacterStatuses"
import shield_gif from '../../assets/shield_animated.gif'
import sword_gif from '../../assets/sword_animated.gif'
import medicine_gif from '../../assets/medicine_animated.gif'

type Props = {
    value?: string
    afflictions?: string[]
    animation?: string
    stopAnimation: () => void,
}

const getAnimation = (animation: string) => {
    if (animation === 'shield') {
        return shield_gif
    } else if (animation === 'attack') {
        return sword_gif
    } else if (animation === 'heal') {
        return medicine_gif
    }

    return sword_gif
}

export const CharacterPortrait = ({ value, afflictions, animation, stopAnimation }: Props) => {
    const classes = classnames(
        'w-14 h-14 flex items-center justify-center text-xl font-bold dark:text-white mx-4'
    )

    const [animating, setAnimating] = useState(false)

    if (animation && !animating) {
        setAnimating(true)
        setTimeout(() => {
            stopAnimation()
            setAnimating(false)
        }, 500)
    }

    return (
        <div className={classes}>
            <div className='scale-150'>
                <img className='scale-150' alt="" src={animation ? getAnimation(animation) : value } />
            </div>
            <CharacterStatuses afflictions={afflictions} />
        </div>
    )
}
